@import url('styles/vars.css');

* {
  box-sizing: border-box;
}

html { overflow-y: scroll }

html * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

/* Table Styles Start */
.table {
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.table > div {
  min-height: 50px;
}

.table.dense > div {
  min-height: 35px;
}

.table.zebra > div:nth-child(even) {
  background: #F5F5F5;
}

.table-row {
  display: flex;
  align-items: center;
  border-bottom: 0.5px lightgray solid;
}

.table-row::after {
  content: "";
  clear: both;
  display: table;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 10px 20px;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

/* Table Styles End */

div {
  outline: 0.0px lightgray solid;  /* div outline for dev */
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.logo-title-container {
  display: flex;
  justify-content: space-between;
}

h2 {
  font-weight: 1.2;
}

#main-page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
    padding-bottom: 1.2rem;    /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.2rem;            /* Footer height */
  font-size: var(--font-s);
  border-top: 0.5px lightgray solid;
}

a {
  color: var(--color-green);
  text-decoration: none;
}

#header-row {
  border-bottom: 0.5px lightgray solid;
}

.invalid {
  border-color: red;
}

.hover-themed:hover {
  color: var(--color-theme-primary-d) !important;
}
